<template>
    <main class="main" data-view="security">
        <section class="section" id="security">
            <div class="row">
                <div class="col col-left">
                    <div class="wrapper">
                        <h3>{{ cc.title }}</h3>
                        <p v-html="cc.text"></p>
                        <img src="/img/pages/security/safety.png" alt="Piggy Guardian" width="417" height="363" />
                    </div>
                </div>

                <div class="col col-right">
                    <div class="card-verical">
                        <div class="row">
                            <h3 class="card-vertical__title">
                              <div class="card-vertical__icon active-call">
                                <img src="/img/pages/security/active-call.svg" alt="Card Icon" width="42" height="42" />
                              </div>
                              {{ cc.item1.title }}
                            </h3>
                            <p v-html="cc.item1.text"></p>
                        </div>
                    </div>

                    <div class="card-verical">
                        <div class="row">
                            <h3 class="card-vertical__title">
                                <div class="card-vertical__icon risk">
                                  <img src="/img/pages/security/risk.svg" alt="Card Icon" width="42" height="42" />
                                </div>
                                {{ cc.item2.title }}
                            </h3>
                            <p v-html="cc.item2.text"></p>
                        </div>
                    </div>
                </div>

                <div class="row-wrapper">
                    <div class="row">
                        <div class="card-verical col">
                            <div class="row">
                                <h3 class="card-vertical__title">
                                    <div class="card-vertical__icon shield">
                                      <img src="/img/pages/security/shield.svg" alt="Card Icon" width="42" height="42" />
                                    </div>
                                    {{ cc.item3.title }}
                                </h3>
                                <p v-html="cc.item3.text"></p>
                            </div>
                        </div>

                        <div class="card-verical col">
                            <div class="row">
                                <h3 class="card-vertical__title">
                                   <div class="card-vertical__icon certificate">
                                      <img src="/img/pages/security/certificate.svg" alt="Card Icon" width="42" height="42" />
                                    </div>
                                    {{ cc.item4.title }}
                                </h3>
                                <p v-html="cc.item4.text"></p>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="card-verical col">
                            <div class="row">
                                <h3 class="card-vertical__title">
                                    <div class="card-vertical__icon stack">
                                      <img src="/img/pages/security/stack.svg" alt="Card Icon" width="42" height="42" />
                                    </div>
                                    {{ cc.item5.title }}
                                </h3>
                                <p v-html="cc.item5.text"></p>
                            </div>
                        </div>

                        <div class="card-verical col">
                            <div class="row">
                                <h3 class="card-vertical__title">
                                    <div class="card-vertical__icon ui-checks">
                                      <img src="/img/pages/security/ui-checks.svg" alt="Card Icon" width="42" height="42" />
                                    </div>
                                    {{ cc.item6.title }}
                                </h3>
                                <p v-html="cc.item6.text"></p>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="card-verical col">
                            <div class="row">
                                <h3 class="card-vertical__title">
                                    <img src="/img/pages/security/finger-print.svg" alt="Card Icon" width="80" height="80" class="card-vertical__icon" />
                                    {{ cc.item7.title }}
                                </h3>
                                <p v-html="cc.item7.text"></p>
                            </div>
                        </div>

                        <div class="card-verical col">
                            <div class="row">
                                <h3 class="card-vertical__title">
                                    <div class="card-vertical__icon hard-drive">
                                      <img src="/img/pages/security/hard-drive.svg" alt="Card Icon" width="42" height="42" />
                                    </div>
                                    {{ cc.item8.title }}
                                </h3>
                                <p v-html="cc.item8.text"></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>
</template>
<script>
export default {
  components: {},
  computed: {
    cc () {
      return this.$t('pages.security')
    }
  },
  mounted () {
    setTimeout(() => {
      window.scrollTo(0, 0)
    }, 100)
  },
  metaInfo () {
    const meta = this.$t('meta.security')
    return {
      titleTemplate: '%s' + (meta.title ? ' | ' + meta.title : ''),
      meta: [
        { name: 'description', content: meta.description },
        { property: 'og:description', content: meta.description }
      ]
    }
  }
}
</script>
